<template>
  <b-form-group
    label="İlgili Kişi"
    label-for="id_com_customer_related_person"
  >
    <v-select
      v-model="dataItem.id_com_customer_related_person"
      :options="dataList"
      label="name"
      :reduce="item => item.id"
      placeholder="Giriniz"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'

import vSelect from 'vue-select'

export default {
  name: 'CrcRelatedPersonSelectCard',
  components: {
    BFormGroup,
    vSelect,

  },
  computed: {
    dataList() {
      return this.$store.getters['customerRelatedPerson/dataList']
    },
    dataItem() {
      return this.$store.getters['crc/dataItem']
    },

  },
}
</script>
