<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>
              Araç Kartı Seçimi
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-form-group
              label="Araç Kartı Ara"
              label-for="search"
            >
              <b-input-group>
                <b-form-input
                  id="search"
                  v-model="search"
                  placeholder="Araç Plaka, Şase No..."
                  @keydown.enter="searchData"
                />
                <b-input-group-append>
                  <b-button
                    type="submit"
                    variant="primary"
                    @click="searchData"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      class="mr-50"
                    />
                    Ara
                  </b-button>
                  <b-button
                    v-b-toggle.sidebar-add-new-car
                    variant="warning"
                  >
                    <feather-icon icon="PlusIcon" />
                    Araç Kartı Ekle
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-card-body>
          <b-table
            striped
            hover
            responsive="sm"
            :fields="fields"
            :items="dataList"
          >
            <template #cell(name)="data">
              <div>{{ data.item.name }}</div>
              <div class="text-primary">
                <small>{{ data.item.company_name }}</small>
              </div>
            </template>
            <template #cell(brand)="data">
              {{ data.item.brand }} {{ data.item.model }} {{ data.item.myear }}
              <div class="text-warning font-small-2">
                {{ data.item.license_plate }}
              </div>
            </template>
            <template #cell(control)="data">
              <b-button
                variant="outline-primary"
                block
                size="sm"
                @click="showModal(data.item.id_com_cars)"
              >
                Kayıt Oluştur
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <crc-add-car-bar :refresh-data="getDataList" />
    <b-modal
      v-model="modalData.status"
      title="Kayıt Oluştur"
      centered
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <crc-type-select-card />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <crc-brand-select-card />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <crc-location-select-card />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <crc-users-select-card />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <crc-close-code-card />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <crc-close-date-card />
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label-for="is_related_person"
          >
            <b-form-checkbox
              id="is_related_person"
              switch
              @change="getRelatedPersons($route.params.id_com_customer)"
            >
              İlgili kişi girişi
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          v-if="modalData.is_related_person"
          cols="12"
          md="12"
        >
          <crc-related-person-select-card v-if="relatedPersons.length > 0" />
          <div
            v-else
          >
            <b-alert
              show
              variant="info"
              class="mt-2"
            >
              <div class="alert-body text-center">
                Müşteri kartına ait ilgili kişi bilgisi bulunmuyor.
                <div class="mt-1">
                  <b-button
                    variant="primary"
                    size="sm"
                    :href="`/customers/edit/${$route.params.id_com_customer}`"
                    target="_blank"
                  >
                    <feather-icon icon="PlusIcon" />Ekle
                  </b-button>
                </div>
              </div>
            </b-alert>
          </div>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="modalData.status=false"
        >
          <feather-icon icon="XIcon" />
          İptal
        </b-button>
        <b-button
          variant="success"
          class="ml-1"
          :disabled="!crcData.id_com_crc_type || !crcData.id_com_brand || !crcData.id_com_location "
          @click="saveData"
        >
          <b-spinner
            v-if="modalData.saving"
            small
          />
          <feather-icon
            v-if="!modalData.saving"
            icon="SaveIcon"
          /> Oluştur
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BTable,
  BCardBody,
  VBToggle,
  BCardHeader,
  BCardTitle,
  BModal,
  BSpinner,
  BFormCheckbox,
  BAlert,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CrcAddCarBar from '@/views/Crm/Crc/Components/AddCar.vue'
import CrcTypeSelectCard from '@/views/Crm/Crc/Elements/CrcType.vue'
import CrcBrandSelectCard from '@/views/Crm/Crc/Elements/CrcBrand.vue'
import CrcLocationSelectCard from '@/views/Crm/Crc/Elements/CrcLocation.vue'
import CrcUsersSelectCard from '@/views/Crm/Crc/Elements/CrcUsers.vue'
import CrcCloseCodeCard from '@/views/Crm/Crc/Elements/CrcCloseCodeCard.vue'
import CrcCloseDateCard from '@/views/Crm/Crc/Elements/CrcCloseDateCard.vue'
import CrcRelatedPersonSelectCard from '@/views/Crm/Crc/Elements/CrcRelatedPersons.vue'

export default {
  name: 'CrcCarSearch',
  components: {
    CrcRelatedPersonSelectCard,
    CrcCloseDateCard,
    CrcCloseCodeCard,
    CrcUsersSelectCard,
    CrcLocationSelectCard,
    CrcBrandSelectCard,
    CrcTypeSelectCard,
    CrcAddCarBar,
    BButton,
    BCard,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BTable,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BModal,
    BSpinner,
    BFormCheckbox,
    BAlert,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      modalData: {
        status: false,
        saving: false,
        id_com_cars: null,
        is_related_person: false,
      },
      search: null,
      fields: [
        {
          key: 'name',
          label: 'MÜŞTERİ',
        },
        {
          key: 'brand',
          label: 'ARAÇ BİLGİSİ',
        },
        {
          key: 'control',
          label: 'Kontrol',
          tdClass: 'text-right width-100 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          'com_customer.id AS id',
          'com_customer.company_name AS company_name',
          'com_customer.name AS name',
          'com_customer.phone AS phone',
          'com_customer.email AS email',
          'com_swap_brand.name AS brand',
          'com_swap_model.title AS model',
          'com_cars.hardware AS hardware',
          'com_cars.id AS id_com_cars',
          'com_cars.license_plate AS license_plate',
          'com_cars.myear AS myear',
          'com_cars.id_com_customer AS id_com_customer',
        ],
        order_by: ['id', 'DESC'],
        where: {},
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['cars/getCars']
    },
    crcData() {
      return this.$store.getters['crc/dataItem']
    },
    relatedPersons() {
      return this.$store.getters['customerRelatedPerson/dataList']
    },
  },
  created() {
    this.getDataList()
    this.$store.commit('crc/RESET_DATA_ITEM')
  },
  methods: {
    searchData() {
      this.dataQuery.or_like = {
        'com_customer.company_name': this.search,
        'com_customer.name': this.search,
        'com_customer.phone': this.search,
        'com_cars.license_plate': this.search,
      }
      this.getDataList()
    },
    getRelatedPersons(id) {
      // eslint-disable-next-line no-bitwise
      this.modalData.is_related_person ^= true
      if (this.modalData.is_related_person) {
        this.$store.dispatch('customerRelatedPerson/getDataList', {
          where: {
            'com_customer_related_person.id_com_customer': id,
          },
        })
      } else {
        this.crcData.id_com_customer_related_person = null
      }
    },
    showModal(idCars) {
      this.$store.commit('crc/RESET_DATA_ITEM')
      this.modalData.status = true
      this.modalData.saving = false
      this.modalData.id_com_cars = idCars
    },
    saveData() {
      this.modalData.saving = true
      this.crcData.id_com_cars = this.modalData.id_com_cars
      this.crcData.id_com_customer = this.$route.params.id_com_customer
      this.$store.dispatch('crc/saveData', this.crcData)
        .then(response => {
          if (response.status) {
            this.modalData.saving = false
            this.modalData.status = false
            this.$swal({
              icon: 'success',
              title: 'İşlem Başarılı',
              text: response.message,
              confirmButtonText: this.$store.state.app.removeResultClose,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.$router.push(`/customer-relation-calls/view/${response.id}`)
          } else {
            this.modalData.saving = false
            this.modalData.status = false
            this.$swal({
              icon: 'error',
              title: 'İşlem Başarısız',
              text: response.message,
              confirmButtonText: this.$store.state.app.removeResultClose,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
    getDataList() {
      this.dataQuery.where['com_cars.id_com_customer'] = this.$route.params.id_com_customer
      this.$store.dispatch('cars/carsList', this.dataQuery)
    },
  },
}
</script>
