<template>
  <b-form-group
    label="Lokasyon"
    label-for="id_com_location"
  >
    <v-select
      v-model="dataItem.id_com_location"
      :options="dataList"
      label="title"
      :reduce="item => item.id"
      placeholder="Giriniz"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'

import vSelect from 'vue-select'

export default {
  name: 'CrcLocationSelectCard',
  components: {
    BFormGroup,
    vSelect,

  },
  computed: {
    dataList() {
      return this.$store.getters['locations/getLocations']
    },
    dataItem() {
      return this.$store.getters['crc/dataItem']
    },

  },
  created() {
    this.setLocations()
  },
  methods: {
    setLocations() {
      this.$store.dispatch('locations/locationsList', {
        select: [
          'com_location.id AS id',
          'com_location.title AS title',
        ],
      })
    },
  },
}
</script>
