<template>
  <b-form-group
    label="İlgili Danışman"
    label-for="id_com_user_1"
  >
    <v-select
      v-model="dataItem.id_com_user_1"
      :options="dataList"
      label="name"
      :reduce="item => item.id"
      placeholder="Giriniz"
    >
      <template v-slot:option="option">
        <div v-if="option.brand">
          {{ option.name }}
          <div>
            <small class="text-warning">{{ option.brand }}</small> /  <small class="text-info">{{ option.department }}</small> /  <small class="text-pink">{{ option.user_type }}</small>
          </div>
        </div>
        <div v-else>
          {{ option.name }}
        </div>
      </template>
      <template v-slot:selected-option="option">
        {{ option.name }}
      </template>
    </v-select>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'

import vSelect from 'vue-select'

export default {
  name: 'CrcUsersSelectCard',
  components: {
    BFormGroup,
    vSelect,

  },
  computed: {
    dataList() {
      return this.$store.getters['users/getUsers']
    },
    dataItem() {
      return this.$store.getters['crc/dataItem']
    },

  },
  created() {
    this.setUsers()
  },
  methods: {
    setUsers() {
      this.$store.dispatch('users/usersList', {
        select: [
          'com_user.id AS id',
          'com_user.name AS name',
          'com_department.title AS department',
          'com_brand.name AS brand',
          'com_user_type.title AS user_type',
        ],
        where: {
          'com_user.status': 1,
        },
      })
    },
  },
}
</script>
